import { Link } from 'react-router-dom'
import { ArrowRight } from 'lucide-react'

export default function DzenyHomePage() {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 flex flex-col justify-center items-center px-4 py-16">
      <header className="mb-12">
        <h1 className="text-4xl md:text-6xl font-bold text-center">Dzeny</h1>
      </header>
      <main className="max-w-2xl text-center">
        <p className="text-xl md:text-2xl mb-8 text-gray-300">
          Персональный проводник в мир ментального здоровья.
        </p>
        <Link to="/login">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition-colors duration-300 flex items-center justify-center mx-auto">
            НАЧАТЬ
            <ArrowRight className="ml-2" size={20} />
          </button>
        </Link>
      </main>
    </div>
  )
}
