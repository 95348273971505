import { useState } from 'react';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { CredentialResponse } from '@react-oauth/google';
import axios, { AxiosResponse } from 'axios';

import './App.scss';
import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';
import { ConsolePage } from './pages/ConsolePage';
import ProfilePage from "./pages/ProfilePage";
import LoginPage from './pages/LoginPage';
import { checkGoogleToken } from './lib/GoogleAuth';
import SessionHistoryPage from './pages/SessionHistoryPage';

declare global {
  var dzenyUrl: string;
}

// globalThis.dzenyUrl = 'http://0.0.0.0:8080';
globalThis.dzenyUrl = 'https://dzeny.proposer.pro';


function App() {
  const [loginErrorState, setLoginErrorState] = useState<string>("");
  const [updateState, setUpdateSet] = useState(0);

  const onGoogleSuccess = (response: CredentialResponse) => {
    localStorage.removeItem('tmp::access_token');
    localStorage.removeItem('tmp::user_name');
    localStorage.removeItem('tmp::user_picture');
    if (response.credential) {
      localStorage.setItem('tmp::google_token', response.credential);
      const userObject = jwtDecode<any>(response.credential)
      if (userObject.name) {
        localStorage.setItem('tmp::user_name', userObject.name);
      }
      if (userObject.picture) {
        localStorage.setItem('tmp::user_picture', userObject.picture);
      }
      axios
        .post(`${globalThis.dzenyUrl}/v1/auth/google`, {
          external_token: response.credential,
        })
        .then(onInternalLoginSuccess)
        .catch(onInternalLoginError);
      setLoginErrorState("");
    } else {
      setLoginErrorState("Ошибка входа, попробуйте еще раз");
    }
  };

  const onGoogleError = () => {
    console.log("Google login error");
    setLoginErrorState("Ошибка входа, попробуйте еще раз");
  };

  const onInternalLoginSuccess = (response: AxiosResponse<any, any>) => {
    localStorage.setItem('tmp::access_token', response.data.access_token);
    setLoginErrorState("");
    setUpdateSet(updateState ^ 1)
  };

  const onInternalLoginError = (error: any) => {
    console.log(error);
    setLoginErrorState("Ошибка входа, попробуйте еще раз");
  };

  const onLogout = () => {
    localStorage.removeItem('tmp::google_token');
    localStorage.removeItem('tmp::access_token');
    setLoginErrorState("");
    setUpdateSet(updateState ^ 1)
  };

  const googleToken = localStorage.getItem('tmp::google_token');
  const accessToken = localStorage.getItem('tmp::access_token');
  let isAuthenticated = false;
  if (googleToken && checkGoogleToken(googleToken) && Boolean(accessToken)) {
    isAuthenticated = true;
  }

  let router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <LoginPage onGoogleSuccess={onGoogleSuccess} onGoogleError={onGoogleError} loginError={loginErrorState} />,
      loader: () => {
        if (isAuthenticated) {
          throw redirect("/profile");
        }
        return null;
      }
    },
    {
      path: "/profile",
      element: <ProfilePage onLogout={onLogout} />,
      loader: () => {
        if (!isAuthenticated) {
          throw redirect("/login");
        }
        return null;
      }
    },
    {
      path: "/sessionHistory/:sessionId",
      element: <SessionHistoryPage />,
      loader: () => {
        if (!isAuthenticated) {
          throw redirect("/login");
        }
        return null;
      }
    },
    {
      path: "/voiceCall/:sessionId",
      element: <ConsolePage />,
      loader: () => {
        if (!isAuthenticated) {
          throw redirect("/login");
        }
        return null;
      }
    },
  ]);
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

export default App;
