import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { BarChart2, Bell, LogOut, ChevronRight, MessageCircle, ArrowRight, Check } from 'lucide-react';
import axios, { AxiosResponse } from 'axios';


interface ProfileProps {
  onLogout: () => void;
}

export default function ProfilePage({ onLogout }: ProfileProps) {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState<{
    id: string,
    user_id: string,
    messages: any[],
    timestamp: number,
    summary: string,
    finished: boolean
  }[]>([]);

  useEffect(() => { fetchSessions() }, []);

  const accessToken = localStorage.getItem('tmp::access_token');

  const fetchSessions = () => {
    // TODO: implement pagination
    axios.get(`${globalThis.dzenyUrl}/v1/user_session?limit=100`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`,
      }
    })
      .then(function(response) {
        setSessions(response.data)
      })
      .catch(function(error) {
        console.log(error);
      })
  };

  const startNewSession = () => {
    axios
      .post(`${globalThis.dzenyUrl}/v1/user_session`, {}, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        }
      }
      )
      .then((response: AxiosResponse<any, any>) => {
        navigate(`/voiceCall/${response.data.id}`);
      })
      .catch(function(error) {
        console.log(error);
      })
  };

  const finishSession = (sessionId: string) => {
    axios
      .patch(`${globalThis.dzenyUrl}/v1/user_session/${sessionId}`,
        { finished: true },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
          }
        }
      )
      .then(() => {
        fetchSessions()
      })
    // .catch();
  };

  const user = {
    name: localStorage.getItem('tmp::user_name') || '',
    avatar: localStorage.getItem('tmp::user_picture') || ''
  }
  const stats = [
    { label: 'Сессий', value: sessions.length, icon: BarChart2 },
    { label: 'Сообщений', value: sessions.reduce((acc: any, value: any) => acc + value.messages.length, 0), icon: MessageCircle },
    { label: 'Оповещений', value: 2, icon: Bell }
  ]

  const latestSessions = sessions.map((session: any) => {
    const date = new Date(session.timestamp * 10);
    const formattedDate = new Intl.DateTimeFormat(
      'en-US',
      { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric' }
    ).format(date);
    return { ...session, time: formattedDate, messagesCount: session.messages.length };
  })

  const finishedSessions = latestSessions.filter((s) => s.finished)
  const pendingSessions = latestSessions.filter((s) => !s.finished)

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 p-6">
      <header className="mb-8 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="flex items-center space-x-4">
          <img src={user.avatar} alt="" className="w-16 h-16 rounded-full bg-gray-700" />
          <div>
            <h1 className="text-2xl font-bold">Добро пожаловать, {user.name}</h1>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 w-full sm:w-auto">
          <button
            onClick={() => startNewSession()}
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center"
          >
            Начать новую сессию
            <ChevronRight className="ml-2" size={20} />
          </button>
          <button
            onClick={onLogout}
            className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center"
          >
            <LogOut className="mr-2" size={20} />
            Выход
          </button>
        </div>
      </header>
      <main>
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Статистика</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {stats.map((stat, index) => (
              <div key={index} className="bg-gray-800 p-4 rounded-lg flex items-center space-x-4">
                <stat.icon className="w-8 h-8 text-blue-400" />
                <div>
                  <p className="text-sm text-gray-400">{stat.label}</p>
                  <p className="text-2xl font-bold">{stat.value}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
        {pendingSessions.length > 0 &&
          <section>
            <h2 className="text-xl font-semibold mb-4">Текущие сессии</h2>
            <div className="bg-gray-800 rounded-lg overflow-hidden">
              <ul>
                {pendingSessions.map((session: any) => (
                  <li key={session.id} className="border-b border-gray-700 last:border-b-0">
                    <div
                      key={session.id}
                      className="p-4 border-b border-gray-700 last:border-b-0"
                    >
                      <div className="flex justify-between items-center">
                        <p>
                          <p className="text-sm text-gray-400">{session.time}</p>
                          <p className="font-medium">{session.messagesCount} сообщений</p>
                        </p>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => navigate(`/voiceCall/${session.id}`)}
                            className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1 rounded-md text-sm flex items-center transition-colors duration-300"
                          >
                            Продолжить
                            <ArrowRight size={16} className="ml-1" />
                          </button>
                          <button
                            onClick={() => finishSession(session.id)}
                            className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1 rounded-md text-sm flex items-center transition-colors duration-300"
                          >
                            Завершить
                            <Check size={16} className="ml-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        }
        <section>
          <h2 className="text-xl font-semibold mb-4">Последние сессии</h2>
          <div className="bg-gray-800 rounded-lg overflow-hidden">
            {finishedSessions.map((session: any) => (
              <div
                onClick={() => navigate(`/sessionHistory/${session.id}`)}
                key={session.id}
                className="p-4 border-b border-gray-700 hover:bg-blue-700 last:border-b-0"
              >
                <p className="text-sm text-gray-400">{session.time}</p>
                <p className="font-medium">{session.messagesCount} сообщений</p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  )
}
