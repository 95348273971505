import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

interface LoginProps {
  onGoogleSuccess: (response: CredentialResponse) => void;
  onGoogleError: () => void;
  loginError: string;
}

export default function LoginPage({ onGoogleSuccess, onGoogleError, loginError }: LoginProps) {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center px-4">
      <h1 className="text-3xl md:text-4xl font-bold text-white mb-8">Вход Dzeny</h1>
      {loginError && (
        <div className="bg-red-500 text-white px-4 py-2 rounded-lg mb-4 max-w-md text-center">
          {loginError}
        </div>
      )}
      <GoogleLogin onSuccess={onGoogleSuccess} onError={onGoogleError} size={"large"} shape={"square"} theme={"filled_blue"} />
    </div>
  )
}
