import { useRouteError, useNavigate, isRouteErrorResponse } from "react-router-dom";
import { AlertTriangle, Home } from 'lucide-react'

interface ErrorPageProps {
  errorCode?: number
  errorMessage?: string
}

export default function ErrorPage({ }: ErrorPageProps) {
  let errorCode = 404;
  let errorMessage = "Page not found";
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    errorCode = error.status;
    errorMessage = error.statusText;
  }
  console.error(error);
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 flex flex-col items-center justify-center p-4">
      <AlertTriangle size={64} className="text-red-500 mb-6" />
      <h1 className="text-4xl font-bold mb-2">Error {errorCode}</h1>
      <p className="text-xl mb-8 text-center">{errorMessage}</p>
      <p className="text-gray-400 mb-8 text-center max-w-md">
        We're sorry, but something went wrong. Please try again or return to the home page.
      </p>
      <button
        onClick={() => navigate("/")}
        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out flex items-center"
      >
        <Home size={20} className="mr-2" />
        Go to Home
      </button>
    </div>
  )
}
